<template>
    <div style="background: var(--v-component-base) !important">
        <v-row justify="end" class="mb-2">
            <!-- <v-col cols="12" sm="12" md="6" lg="6">
                <div class="text-center">
                    <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
                    :page-size.sync="params.limit" :page-sizes="[5, 10, 15, 25]" 
                    :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper, total' : 'sizes, prev, pager, next'" :total="total"></el-pagination>
                </div>
                
            </v-col> -->
            <v-col cols="12" sm="12" md="6" lg="6">
                <el-input suffix-icon="el-icon-search" v-model="puSearch" clearable placeholder="Search">
                </el-input>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col cols="12">
                <v-data-table :key="updateKey" :headers="fboHeaders" style="cursor: pointer;" :loading="fboLoading"
                    :options="{
                        page: page,
                        itemsPerPage: params.limit,
                        pageStart: 1,
                    }"
                    :items="fboRecords" item-key="fboCode" dense hide-default-footer :expanded.sync="expanded" :single-expand="singleExpand">
                    <template v-slot:item="{ item }">
                        <tr @click="fetchOrchardInfo(item)">
                            <td v-if="item.valid === true" class="text-center"><v-icon color="green" class="text-center">check_circle</v-icon></td>
                            <td v-else-if="item.valid === false" class="text-center"><v-icon color="red" class="text-center">dangerous</v-icon></td>
                            <td v-else class="text-center"><v-icon class="text-center">question_mark</v-icon></td>
                            <!-- <td class="text-right">{{ item.id }}</td> -->
                            <td class="text-right">{{ item.fboCode }}</td>
                            <td class="text-left">{{ item.fboName }}</td>
                            <td class="text-left">{{ item.fboType }}</td>
                            <td class="text-left">{{ item.source }}</td>
                            <td class="text-left">{{ item.productionArea }}</td>
                            <td class="text-left">{{ item.productionCode }}</td>
                            <td class="text-left">{{ item.address }}</td>
                            <td class="text-left">{{ item.city }}</td>
                            <td class="text-right">{{ item.llPU }}</td>
                        </tr>
                    </template>         
                    <template v-slot:expanded-item="{ headers}">
                        <td :colspan="headers.length">Expanded Content</td>
                    </template>                     
                </v-data-table>
            </v-col>
            <v-col cols="12" class="text-center">
                <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
                :page-size.sync="params.limit" :page-sizes="[5, 10, 15, 25]" 
                :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper, total' : 'sizes, prev, pager, next'" :total="total"></el-pagination>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogOrchard" width="80%" height="80vh" class="black-dialog">
                <v-card> 
                    <v-toolbar>
                        <h3>
                            Orchard Information Of '{{ this.selectedFBO }}'
                        </h3>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="dialogMapping = true">
                            MAP
                        </v-btn>
                        <v-btn icon @click="dialogOrchard = false">
                            <v-icon color="primary">close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row class="mt-3" >
                            <v-spacer></v-spacer>
                            <v-col cols="12" sm="12" md="6" lg="6" class="text-right">
                                <el-input suffix-icon="el-icon-search" v-model="orchardSearch" clearable placeholder="Search">
                                </el-input>
                            </v-col>
                        <v-col cols="12" class="text-center">
                            <v-data-table :key="orchardUpdateKey" :headers="orchardHeaders" style="cursor: pointer;" :loading="orchardLoading"
                            :options="{
                            page: orchardPage,
                            itemsPerPage: orchardParams.limit,
                            pageStart: 1,
                            }" :items="orchardRecords" item-key="orchardId" dense hide-default-footer>
                            </v-data-table>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="orchardPage" :pager-count="5"
                            :page-size.sync="orchardParams.limit" :page-sizes="[5, 10, 15, 25]" 
                            :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper, total' : 'sizes, prev, pager, next'" :total="orchardTotal"></el-pagination>
                        </v-col>
                    </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogMapping" width="94vw" style="overflow-y: hidden" persistent>
                <v-card style="border-radius: 20px; overflow-y: hidden; overflow-x: hidden">
                    <v-toolbar>
                        <v-toolbar-title>
                            Orchard Mapping
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" icon @click="dialogMapping = false"><v-icon>close</v-icon></v-btn>
                    </v-toolbar>
                    <v-row no-gutters style="height: 80vh;">
                        <MainPoi :orchards="this.orchardRecords" @savedOrchard="reloadOrchards"/>
                    </v-row>
                </v-card> 
            </v-dialog>

        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color">{{ snackbar.text
            }}</v-snackbar>
    </div>
</template>

<script>
import MainPoi from '../POI/MainPoi.vue'

export default {
    props: ['orgId'],
    components: {
        MainPoi,
    },
    data: () => ({
        fboRecords: [],
        fboHeaders: [
            {
                text: 'Validated?',
                align: 'center',
                sortable: false,
                value: 'valid'
            },
            // {
            //     text: 'ID',
            //     value: 'id',
            //     align: 'right',
            // }, 
            {
                text: 'FBO Code',
                value: 'fboCode',
                align: 'right',
            },
            {
                text: 'FBO Name',
                value: 'fboName',
                align: 'left',
            },
            {
                text: 'FBO Type',
                value: 'fboType',
                align: 'left',
            },
            {
                text: 'Source',
                value: 'source',
                align: 'left',
            },
            {
                text: 'Production Area',
                value: 'productionArea',
                align: 'left',
            },
            {
                text: 'Production Area Code',
                value: 'productionCode',
                align: 'left',
            },
            {
                text: 'Address',
                value: 'address',
                align: 'left',
            },
            {
                text: 'City',
                value: 'city',
                align: 'left',
            },
            {
                text: 'LL Production Unit',
                value: 'llPU',
                align: 'right',
            },
        ],
        params: {
            limit: 15,
            offset: 0    
        },
        page: 1,
        total: 100,
        updateKey: 0,
        puSearch: null,
        fboLoading: false,
        expanded: [],
        singleExpand: true,
        snackbar: {
                show: false,
                text: "",
                timeout: 3000,
                color: "success"
            },
        dialogOrchard: false,
        orchardRecords: [],
        orchardHeaders: [
            {
                text: 'Orchard ID',
                value: 'orchardId',
                align: 'right',
            }, 
            {
                text: 'Orchard Name',
                value: 'orchardName',
                align: 'left',
            },
            {
                text: 'District',
                value: 'district',
                align: 'left',
            },
            {
                text: 'Province',
                value: 'province',
                align: 'left',
            },
            {
                text: 'Product',
                value: 'product',
                align: 'left',
            },
            {
                text: 'Commodity',
                value: 'commodity',
                align: 'left',
            },
            {
                text: 'Cultivar',
                value: 'cultivar',
                align: 'left',
            },
        ],
        orchardParams: {
            limit: 15,
            offset: 0    
        },
        orchardPage: 1,
        orchardTotal: 100,
        orchardUpdateKey: 0,
        orchardSearch: null,
        orchardLoading: false,
        selectedFBO: '',
        dialogMapping: false,
        selectedRow: null,
    }),
    watch: {
        page: {
            handler: async function () {
                console.log("PAGE NUMBER CHANGED")
                this.params.offset = (this.page - 1) * this.params.limit;
                this.fetchFBOs()
                this.updateKey++
            },
            immediate: true
        },
        'params.limit': {
            handler: async function () {
                console.log("RECORD LIMIT CHANGED")
                this.params.offset = (this.page - 1) * this.params.limit;
                this.fetchFBOs()
                this.updateKey++
            },
            immediate: true
        },
        puSearch: {
            handler: function () {
                setTimeout(() => {
                    this.fetchFBOs()
                }, 2000);
            },
            immediate: true
        },
        orchardPage: {
            handler: async function () {
                console.log("PAGE NUMBER CHANGED")
                this.orchardParams.offset = (this.orchardPage - 1) * this.orchardParams.limit;
                this.fetchOrchardInfo(this.selectedRow)
                this.updateKey++
            },
            immediate: true
        },
        'orchardParams.limit': {
            handler: async function () {
                console.log("RECORD LIMIT CHANGED")
                this.orchardParams.offset = (this.orchardPage - 1) * this.orchardParams.limit;
                this.fetchOrchardInfo(this.selectedRow)
                this.updateKey++
            },
            immediate: true
        },
        orchardSearch: {
            handler: function () {
                setTimeout(() => {
                    this.fetchOrchardInfo(this.selectedRow)
                }, 2000);
            },
            immediate: true
        },
    },
    async created() {
    },
    methods: {
        async fetchFBOs() {
            try {
                this.fboLoading = true;
                let search = this.puSearch
                let params = {}
                params.orgId = this.orgId
                params.limit = this.params.limit
                params.offset = this.params.offset
                if (search) {
                    params.search = search
                }
                let fboResponse = await this.$API.fetchFBOs(params)
                // console.log({fboResponse})
                let fboArr = fboResponse.fetchedInfo
                this.total = fboResponse.totalCount
                // console.log(fboArr)
                // this.fboHeaders = Object.keys(fboArr[0]);
                // console.log(this.fboHeaders)
                this.fboRecords = fboArr;
                this.fboLoading = false;

                // let summaryResponse;
                // let paramsSummary = {}
                // paramsSummary.orgId = this.orgId
                // for (let i = 8; i < 47; i++) {
                //     setTimeout(async () => {
                //         paramsSummary.pageNumber = i
                //         summaryResponse = await this.$API.fetchSummaryData(paramsSummary)
                //         console.log(summaryResponse)
                //     }, 5000);
                // }
            } catch (e) {
                console.log(e)
            }
        },
        async fetchOrchardInfo(row) {
            console.log(row)
            this.selectedRow = row;
            this.orchardLoading = true
            if (row.valid === true) {
                if (row.fboType === "Agri Production") {
                    console.log("CORRECT")
                    this.dialogOrchard = true
                    let fboName = row.fboName
                    let fboCode = row.fboCode
                    this.selectedFBO = fboName
                    let params = {}
                    let obj = {}
                    obj.fboCode = fboCode;
                    obj.fboName = fboName;
                    params.limit = this.orchardParams.limit;
                    params.offset = this.orchardParams.offset;
                    if (this.orchardSearch) {
                        console.log(this.orchardSearch)
                        params.search = this.orchardSearch
                    }
                    console.log(params)
                    let response = await this.$API.fetchOrchardInfo(obj, params)
                    this.orchardRecords = response.records
                    this.orchardTotal = response.total
                    this.orchardLoading = false
                } else {
                console.log("INCORRECT")
                }
            } else {
                this.snackbar.show = true;
                this.snackbar.color = "error"
                this.snackbar.text = "Invalid FBO"
            }
        },
        async reloadOrchards(value) {
            console.log({value})
            if (value) {
                console.log("TRUEEEEEEEEEEEe")
                await this.fetchOrchardInfo(this.selectedRow)
            } else {
                console.log("FALSEEEEEEEEee")
            }
        }
    }
}
</script>
